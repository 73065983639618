import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/Layout";
import QFaqPage from "../queries/QFaqPage";
import FaqPage from "../components/FaqPage";
import PageHelmet from "../components/PageHelmet";
import { SanityFaqPage } from "../../graphql-types";

interface FaqPageProps {
  faqPage: SanityFaqPage;
}

const Faq = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const renderFaqPage = ({ faqPage }: FaqPageProps) => {
    return (
      <Layout>
        <PageHelmet title={faqPage.pageTitle} />
        <FaqPage {...faqPage} />
      </Layout>
    );
  };

  return <QFaqPage>{renderFaqPage}</QFaqPage>;
};

export default Faq;
