import { FC } from "react";
import { SanityFaqPage } from "../../graphql-types";
import { cn } from "../lib/helpers";
import * as styles from "./FaqPage.module.scss";
import { pageTitleStyle } from "./typography.module.scss";

import FaqList from "./FaqList";
import Container from "./Container";
import { pageMinHeight } from "../styles/utils.module.scss";

export interface FaqPageProps extends SanityFaqPage {}

const FaqPage: FC<FaqPageProps> = ({
  pageTitle,
  _rawQuestionsAndAnswers,
  questionsAndAnswers,
}) => {
  return (
    <main className={cn(styles.root, pageMinHeight)}>
      <Container noPaddingMobile>
        <div>
          {pageTitle && (
            <h2 className={cn(pageTitleStyle, styles.title)}>{pageTitle}</h2>
          )}
          <FaqList
            questionsAndAnswers={_rawQuestionsAndAnswers || questionsAndAnswers}
          />
        </div>
      </Container>
    </main>
  );
};

export default FaqPage;
